<template lang="pug">
#type-container.filter-uniq-type-container(
  v-show="allTypes.length > 1"
)
  .container-user
    .row
      .col-md-12(v-if="isMobileWidth")
        .filter-uniq-type.filter-uniq-type-mobile
          .filter-title-uniq {{ $t("typeSelect.text") }} &#8811;
          div
            .filter-item-uniq
              v-drop-down(
                option-label="name"
                option-value="id"
                label=""
                v-model="type"
                :options="mobileTypesOptions"
                :disabled="false"
                :multiple="false"
                @click="change($event)"
              )
      .col-md-12(v-if="!isMobileWidth")
        .filter-uniq-type.filter-uniq-type-desktop
          .filter-title-uniq {{ $t("typeSelect.text") }}:
          .uniq-types-list
            .uniq-types-option(
              v-for="(option, key) in typesOptions"
              :class="{'active': type.includes(option.type.id)}"
              :key="key"
            )
              label
                input(
                  type="radio"
                  name="uniq-type"
                  v-model="type"
                  :value="option.type.id"
                  @change="change(option.type.id)"
                )
                span
                  img(
                    v-show="option.image"
                    :src="option.image"
                    :alt="option.type.name"
                  )
                  span {{ option.type.name }}

</template>

<script>
import {mapState} from 'vuex';

export default {
  components: {
    VDropDown: () => import("@/components/helps/DropDown"),
  },
  data: () => ({
    isMobileWidth: window.matchMedia("(max-width: 480px)").matches,
  }),
  created() {
    const _this = this;

    window.addEventListener('resize', function() {
      _this.checkMobileWidth();
    })
  },
  computed: {
    ...mapState('filter', ['allTypes']),
    ...mapState('global', ['generalFormsCollectionName']),
    type: {
      get() {
        return this.$store.state.filter.type;
      },
      set(value) {
        this.$store.commit("filter/setType", value);
      }
    },
    typesOptions() {
      const filteredTypes = this.allTypes.filter((type) => {
        const finforms = type.financeForms.data

        const isInCollection = finforms.some((item) => {
          return (
            item.type.toLowerCase() ===
            this.generalFormsCollectionName.toLowerCase()
          )
        })

        if (isInCollection) return type
      })

      return filteredTypes
    },
    mobileTypesOptions() {
      return this.allTypes.reduce((arr, next) => {
        arr.push(next.type)

        return arr
      }, [])
    },
  },
  methods: {
    change(id) {
      this.$emit('change', id);

      const toTheElement = document.getElementById('type-container');

      setTimeout(function() {
        toTheElement.scrollIntoView({
          block: "start",
          inline: "nearest",
        });
      }, 0)
    },
    checkMobileWidth() {
      return this.$set(this.$data, 'isMobileWidth', window.matchMedia("(max-width: 480px)").matches);
    }
  }
}
</script>